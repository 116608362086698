//PokerViewer.js


import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import _ from "lodash";
import {Dropdown, Button, Checkbox, Input, Table, Tab, List, Image} from "semantic-ui-react";
import './CharacterViewer.css'
import './TreeViewer.css'
import {useStore, updateTheStore} from './newState'
import {businessTemplates} from "./businesses";
import * as bh from './businessHandler'
import {getCopyOf, loadAllRelevantFromDB} from "./utilityFunctions";
import * as constants from './constants'
import {get} from "firebase/database";
import * as ch from './currencyHandler'
import {v4 as uuid} from 'uuid';
import * as fb from './firebaseHandler'
import * as poker from './poker'



function getRandomColor() {
    let hex= ['0','1','2','3','4','5','6','7','8','9','a','b','c','d','e','f']
    let r = Math.floor(Math.random()*16)
    let g = Math.floor(Math.random()*16)
    let b = Math.floor(Math.random()*16)


    return hex[r]+hex[g]+hex[b]

}

function getRandomNumber(max=6) {
    return Math.floor(Math.random()*max)
}



async function getTree() {

	let griffonList = await fb.getGriffonTree()

	updateTheStore(s => {
        s.griffonList = griffonList
    })
        fixGriffonTree()

}

function fixGriffonTree() {

    let testState = useStore.getState()
    let griffonList = getCopyOf(testState.griffonList)

    let startId = 'G1'

    let griffon = griffonList[startId]

    if(!griffon) {
    	return
    }

    let parents 
    if(griffon.parents) {
    	let pIds = griffon.parents.split(",")
    	for(const id of pIds) {
    	   let parent = griffonList[id]
    	   if(parent) {
    	   	parents.push(parent)
    	   }
    	}
    }
    let children = Object.values(griffonList).filter((g) => {
    	return g.parents?.includes(startId)
    })

    griffon.parents = parents
    griffon.children = children

    let clutches = {}

    for(const key of Object.keys(children)) {

    	let child = children[key]
    	if(!clutches[child.clutch]) {
    		clutches[child.clutch] = {children: [], id: child.clutch, parents: child.parents}
    	}

    	clutches[child.clutch].children.push(child)

    }

    let clutchesByMate = _.groupBy(Object.values(clutches), 'parents')

    griffon.clutches = clutches
    griffon.clutchesByMate = clutchesByMate


    updateTheStore(s => {
        s.griffon = griffon
    })


}

function getGriffonTableData(griffon) {

    if(!griffon || griffon.notFetched) {
	return []
    }

   let childColumns = 1
   let clutchColumns = {}
   let par = griffon.parents || ""
   let parents = par.split(",")
   while (parents.length <2) {
   	parents.push("")
   }
   let mateColumns = {}
   for(const key of Object.keys(griffon.clutches)) {
   	clutchColumns[key] = griffon.clutches[key].children.length
   }
   for(const key of Object.keys(griffon.clutchesByMate)) {
   	mateColumns[key] = 0
   	for(const clutch of griffon.clutchesByMate[key]) {
   		mateColumns[key] += clutchColumns[clutch.id]
   	}
   }
   let nameColumns = 0
   for(const key of Object.keys(mateColumns)) {
   	nameColumns += mateColumns[key]
   }
   let parentOneColumns= Math.floor(nameColumns/2)
   let parentTwoColumns= Math.floor(nameColumns/2) + (nameColumns%2)


   let rowOneData= [{value: parents[0], cols: parentOneColumns, color:""}, {value: parents[1], cols: parentTwoColumns, color:""}] 
   let rowTwoData = [{value: griffon.id, cols: nameColumns, color:""}]
   let rowThreeData = []
   let rowFourData = []
   let rowFiveData = []
   for(const key of Object.keys(griffon.clutchesByMate)) {
   	let cols = mateColumns[key]
   	let value = key
   	rowThreeData.push({cols, value, color:""})
   	for(const clutch of griffon.clutchesByMate[key]) {
   		let clutchCols = clutchColumns[clutch.id]
   		let clutchVal = clutch.id
   		rowFourData.push({cols: clutchCols, value: clutchVal, color:""})
   		let clutchColor = getRandomNumber()
   		for(const child of clutch.children) {
   			rowFiveData.push({cols: 1, value: child.id, color:clutchColor })
   		}
   	}
   }


  
   return [rowOneData, rowTwoData, rowThreeData, rowFiveData]


}




const TreeViewer = () => {

    let testState = useStore.getState()

    let tableData = getGriffonTableData(testState.griffon)
   


    return <><div>
    	<Button onClick={() => getTree()} >Hämta Gripar</Button>
    	<div>{JSON.stringify(testState.griffonList)}<br /></div>
    	<h4>Griffon</h4>
    	<p>{JSON.stringify(testState.griffon)}</p>
    	<h4>Children by Clutch</h4>
    	<p>{JSON.stringify(testState.griffon.childrenByClutch)}</p>
    	<h4>Children by Clutch By Mate</h4>
    	<p>{JSON.stringify(testState.griffon.clutchesByMate)}</p>
    	<h4>Test</h4>

    	<table>

    	{tableData.map((arr) => <><tr className={"treeTable"}> {arr.map((col) => <><td className={"treeTable"+col.color} colspan={col.cols}>{col.value}</td></>)}   </tr></>  )}
    	</table>
 
    </div></>
};


export default TreeViewer;



