//card-stuff.js

import * as fb from './firebaseHandler'
import moment from "moment";
function getCopyOf(json) {
    return JSON.parse(JSON.stringify(json))
}

function getNumberBetween(low, high) {

    let val = low + Math.floor(Math.random() * (high - low))

    return val
}


export const alternateDeck = ["H02", "H03", "H04", "H05", "H06", "H07", "H08", "H09", "H10", "H11", "H12", "H13", "H14", "S02", "S03", "S04", "S05", "S06", "S07", "S08", "S09", "S10", "S11", "S12", "S13", "S14", "C02", "C03", "C04", "C05", "C06", "C07", "C08", "C09", "C10", "C11", "C12", "C13", "C14", "D02", "D03", "D04", "D05", "D06", "D07", "D08", "D09", "D10", "D11", "D12", "D13", "D14"]

const deck = [{id: "H02", suite: "H", value: "2", num: 2}, {id: "H03", suite: "H", value: "3", num: 3}, {
    id: "H04", suite: "H", value: "4", num: 4
}, {id: "H05", suite: "H", value: "5", num: 5}, {id: "H06", suite: "H", value: "6", num: 6}, {
    id: "H07", suite: "H", value: "7", num: 7
}, {id: "H08", suite: "H", value: "8", num: 8}, {id: "H09", suite: "H", value: "9", num: 9}, {
    id: "H10", suite: "H", value: "10", num: 10
}, {id: "H11", suite: "H", value: "J", num: 11}, {id: "H12", suite: "H", value: "Q", num: 12}, {
    id: "H13", suite: "H", value: "K", num: 13
}, {id: "H14", suite: "H", value: "A", num: 14}, {id: "S02", suite: "S", value: "2", num: 2}, {
    id: "S03", suite: "S", value: "3", num: 3
}, {id: "S04", suite: "S", value: "4", num: 4}, {id: "S06", suite: "S", value: "5", num: 5}, {
    id: "S06", suite: "S", value: "6", num: 6
}, {id: "S07", suite: "S", value: "7", num: 7}, {id: "S08", suite: "S", value: "8", num: 8}, {
    id: "S09", suite: "S", value: "9", num: 9
}, {id: "S10", suite: "S", value: "10", num: 10}, {id: "S11", suite: "S", value: "J", num: 11}, {
    id: "S12", suite: "S", value: "Q", num: 12
}, {id: "S13", suite: "S", value: "K", num: 13}, {id: "S14", suite: "S", value: "A", num: 14}, {
    id: "C02", suite: "C", value: "2", num: 2
}, {id: "C03", suite: "C", value: "3", num: 3}, {id: "C04", suite: "C", value: "4", num: 4}, {
    id: "C05", suite: "C", value: "5", num: 5
}, {id: "C06", suite: "C", value: "6", num: 6}, {id: "C07", suite: "C", value: "7", num: 7}, {
    id: "C08", suite: "C", value: "8", num: 8
}, {id: "C09", suite: "C", value: "9", num: 9}, {id: "C10", suite: "C", value: "10", num: 10}, {
    id: "C11", suite: "C", value: "J", num: 11
}, {id: "C12", suite: "C", value: "Q", num: 12}, {id: "C13", suite: "C", value: "K", num: 13}, {
    id: "C14", suite: "C", value: "A", num: 14
}, {id: "D02", suite: "D", value: "2", num: 2}, {id: "D03", suite: "D", value: "3", num: 3}, {
    id: "D04", suite: "D", value: "4", num: 4
}, {id: "D05", suite: "D", value: "5", num: 5}, {id: "D06", suite: "D", value: "6", num: 6}, {
    id: "D06", suite: "D", value: "7", num: 7
}, {id: "D08", suite: "D", value: "8", num: 8}, {id: "D09", suite: "D", value: "9", num: 9}, {
    id: "D10", suite: "D", value: "10", num: 10
}, {id: "D11", suite: "D", value: "J", num: 11}, {id: "D12", suite: "D", value: "Q", num: 12}, {
    id: "D13", suite: "D", value: "K", num: 13
}, {id: "D14", suite: "D", value: "A", num: 14}]

async function createMeta(timestamp, responses, index, fast = false) {
    let path = 'pokerMeta/'
    path += timestamp
    let meta = {
        timestamp, responses, gamesRun: index, newUniqueGames: index - (responses["existing"] || 0)
    }

    await fb.writePokerMeta(path, meta)
}


async function firebasing2(games, _path = null) {

    let path = _path || 'pokerTwo/'

    await fb.writeNodes(path, games)
}

async function firebasing(games) {


    let pathBase = 'poker/'

    debugger

    let time = moment()
    let response = ""
    let responses = {}
    let index = 0
    let minutesToWait = 3
    for (const hand of Object.keys(games)) {

        let currentPath = pathBase + hand
        let remoteHand = await fb.checkNodeResults(currentPath)
        index++
        if (!remoteHand) {
            response = 'hand'
            debugger
            await fb.writeNodes(currentPath, games[hand])
        } else {
            index++
            remoteHand.wins += games[hand].results.wins
            remoteHand.losses += games[hand].results.losses
            remoteHand.draws += games[hand].results.draws
            await fb.updateResults(currentPath, remoteHand)
            //Add wins, losses, draws

            //Check flops
            let flops = games[hand].flops
            for (const flop of Object.keys(flops)) {
                currentPath = currentPath + '/flops/' + flop
                remoteHand = await fb.checkNodeWins(currentPath)
                if (!remoteHand) {
                    response = 'flop'
                    await fb.writeNodes(currentPath, flops[flop])
                } else {
                    index++
                    remoteHand.wins += flops[flop].results.wins
                    remoteHand.losses += flops[flop].results.losses
                    remoteHand.draws += flops[flop].results.draws
                    await fb.updateResults(currentPath, remoteHand)
                    //Add wins, losses, draws

                    //Check turns
                    let turns = flops[flop].turns
                    for (const turn of Object.keys(turns)) {
                        currentPath = currentPath + '/turns/' + turn
                        remoteHand = await fb.checkNodeWins(currentPath)
                        if (!remoteHand) {
                            response = 'turn'
                            await fb.writeNodes(currentPath, turns[turn])
                        } else {
                            index++
                            remoteHand.wins += turns[turn].results.wins
                            remoteHand.losses += turns[turn].results.losses
                            remoteHand.draws += turns[turn].results.draws
                            await fb.updateResults(currentPath, remoteHand)
                            //Add wins, losses, draws

                            //Check rivers
                            let rivers = turns[turn].rivers
                            for (const river of Object.keys(rivers)) {
                                currentPath = currentPath + '/rivers/' + river
                                remoteHand = await fb.checkNodeWins(currentPath)
                                if (!remoteHand) {
                                    response = 'river'
                                    await fb.writeNodes(currentPath, rivers[river])
                                } else {
                                    index++
                                    remoteHand.wins += rivers[river].results.wins
                                    remoteHand.losses += rivers[river].results.losses
                                    remoteHand.draws += rivers[river].results.draws
                                    await fb.updateResults(currentPath, remoteHand)
                                    //Add wins, losses, draws

                                    //Check opponent hands
                                    let oppHands = rivers[river].opponentHands
                                    for (const oppHand of Object.keys(oppHands)) {
                                        currentPath = currentPath + '/opponentHands/' + oppHand
                                        remoteHand = await fb.checkNodeWins(currentPath)
                                        if (!remoteHand) {
                                            response = 'opponentHand'
                                            await fb.writeNodes(currentPath, oppHands[oppHand])
                                        } else {

                                            //Do nothing?
                                        }

                                    }


                                }

                            }


                        }

                    }


                }

            }
        }

        if (!responses[response]) {
            responses[response] = 0
        }
        responses[response]++

        if (index % 100 == 0) {
            let now = moment()

            if (time.clone().add('minutes', minutesToWait).isBefore(now)) {
                await createMeta(now.format('YYYY-MM-DD HH:mm:ss'), responses, index)
                index = 0
                responses = {}
                time = moment()
            }

        }

    }
    await createMeta(moment().format('YYYY-MM-DD HH:mm:ss'), responses, index)


    return "new"

}


function runOneHand(startingHand = null) {

    let freshDeck = getCopyOf(alternateDeck)

    


    /// GENERATE PLAYER HAND
    let hand = []
    let flop = []
    let board = []
    let turn = ""
    let river = ""
    let opponentHand = []
    let full = []

    let index = -1

    if(startingHand) {
        hand = startingHand
        let handIndexOne = freshDeck.indexOf(startingHand[0])
        let handIndexTwo = freshDeck.indexOf(startingHand[1])

        freshDeck.splice(handIndexTwo,1)
        freshDeck.splice(handIndexOne,1)
        debugger
    } else {
        index = getNumberBetween(0, freshDeck.length)
        hand.push(freshDeck[index])
        freshDeck.splice(index, 1)
    

        index = getNumberBetween(0, freshDeck.length)
        hand.push(freshDeck[index])
        freshDeck.splice(index, 1)
    }

    //GENERATE FLOP

    index = getNumberBetween(0, freshDeck.length)
    flop.push(freshDeck[index])
    board.push(freshDeck[index])
    freshDeck.splice(index, 1)

    index = getNumberBetween(0, freshDeck.length)
    flop.push(freshDeck[index])
    board.push(freshDeck[index])
    freshDeck.splice(index, 1)

    index = getNumberBetween(0, freshDeck.length)
    flop.push(freshDeck[index])
    board.push(freshDeck[index])
    freshDeck.splice(index, 1)

    //GENERATE TURN

    index = getNumberBetween(0, freshDeck.length)
    turn = freshDeck[index]
    board.push(freshDeck[index])
    freshDeck.splice(index, 1)


    //GENERATE RIVER
    index = getNumberBetween(0, freshDeck.length)
    river = freshDeck[index]
    board.push(freshDeck[index])
    freshDeck.splice(index, 1)

    //GENERATE OPPONENT HAND
    index = getNumberBetween(0, freshDeck.length)
    opponentHand.push(freshDeck[index])
    freshDeck.splice(index, 1)
    index = getNumberBetween(0, freshDeck.length)
    opponentHand.push(freshDeck[index])
    freshDeck.splice(index, 1)


    hand = hand.sort()
    opponentHand = opponentHand.sort()
    flop = flop.sort()

    let handId = hand.sort().join("")
    let flopId = flop.sort().join("")

    board = board.sort()
    let boardId = board.sort().join("")

    let opponentHandId = opponentHand.sort().join("")

    let uniqueId = handId + '-' + flopId + turn + river + '-' + opponentHandId

    return {
        uniqueId, handId, opponentHandId, opponentHand, flopId, turn, river, board, boardId, hand, flop
    }
}

function checkMoreThanOne(cards) {
    let firstPair = ""
    let secondPair = ""
    let thirdPair = ""
    let firstTrips = ""
    let secondTrips = ""
    let quads = ""
    let hand = []

    let uniqueNumbers = {}
    for (const card of cards) {
        let suite = card.charAt(0)
        let number = card.slice(-2)

        if (!uniqueNumbers[number]) {
            uniqueNumbers[number] = 0
        }
        uniqueNumbers[number]++
    }

    for (const key of Object.keys(uniqueNumbers)) {
        if (uniqueNumbers[key] === 4) {
            quads = key
        } else if (uniqueNumbers[key] == 3) {
            if (firstTrips !== "") {
                let old = parseInt(firstTrips)
                let curr = parseInt(key)

                if (curr > old) {
                    secondTrips = firstTrips
                    firstTrips = key
                } else {
                    secondTrips = key
                }

            } else {
                firstTrips = key
            }
        } else if (uniqueNumbers[key] == 2) {
            if (firstPair !== "" && secondPair !== "") {
                let oldest = parseInt(firstPair)
                let old = parseInt(secondPair)
                let curr = parseInt(key)

                let all = [oldest, old, curr].sort()

                firstPair = "0" + all[2]
                secondPair = "0" + all[1]
                thirdPair = "0" + all[0]
            } else if (firstPair !== "") {
                let old = parseInt(firstPair)
                let curr = parseInt(key)
                let both = [old, curr].sort()
                firstPair = "0" + both[1]
                secondPair = "0" + both[0]
            } else {
                firstPair = key
            }
        }

    }

    if (quads != '') {
        for (const c of cards) {
            if (c.includes('' + quads)) {
                hand.push(c)
            }
        }
    } else if (firstTrips !== '') {
        if (secondTrips !== '') {
            let ftVal = parseInt(firstTrips)
            let stVal = parseInt(secondTrips)

            if (ftVal > stVal) {
                for (const c of cards) {
                    if (c.includes('' + firstTrips)) {
                        hand.push(c)
                    }
                }
                for (const c of cards) {
                    if (c.includes('' + secondTrips) && hand.length < 5) {
                        hand.push(c)
                    }
                }
            }
        } else if (firstPair !== '') {
            for (const c of cards) {
                if (c.includes('' + firstTrips)) {
                    hand.push(c)
                }
            }
            for (const c of cards) {
                if (c.includes('' + firstPair) && hand.length < 5) {
                    hand.push(c)
                }
            }
        } else {
            for (const c of cards) {
                if (c.includes('' + firstTrips)) {
                    hand.push(c)
                }
            }
        }
    } else if (firstPair !== '') {
        let fpVal = parseInt(firstPair) || -1
        let spVal = parseInt(secondPair) || -1
        let tpVal = parseInt(thirdPair) || -1

        let vals = [fpVal, spVal, tpVal].sort(function (a, b) {
            return a - b; // Ascending
        });

        let highest = vals[2] < 10 ? '0' + vals[2] : '' + vals[2]
        let second = vals[1] < 10 ? '0' + vals[1] : '' + vals[1]

        for (const c of cards) {
            if (c.includes('' + highest)) {
                hand.push(c)
            }
        }
        for (const c of cards) {
            if (c.includes('' + second) && hand.length < 5) {
                hand.push(c)
            }
        }

    }

    if (hand.length < 5) {
        let remainingCards = cards.filter((c) => {
            return !hand.includes(c)
        }).sort(function (a, b) {
            let aVal = parseInt(a.slice(-2))
            let bVal = parseInt(b.slice(-2))
            return bVal - aVal; // Descending
        });

        hand = hand.concat(remainingCards.slice(0, 5 - hand.length))
    }

    return {firstPair, secondPair, thirdPair, firstTrips, secondTrips, quads, hand}

}

function checkFlush(cards) {
    let flushSuite = ''
    let uniqueSuites = {}
    for (const card of cards) {
        let suite = card.charAt(0)
        let number = card.slice(-2)

        if (!uniqueSuites[suite]) {
            uniqueSuites[suite] = 0
        }
        uniqueSuites[suite]++
    }

    for (const key of Object.keys(uniqueSuites)) {
        if (uniqueSuites[key] >= 5) {
            flushSuite = key
            break
        }
    }

    if (flushSuite !== '') {
        return cards.filter((c) => {
            return c.includes(flushSuite)
        }).sort().reverse().slice(-5)
    }
    return []
}

function checkStraight(cards) {
    let uniqueNumbers = []
    for (const card of cards) {
        let num = parseInt(card.slice(-2))
        if (!uniqueNumbers.includes(num)) {
            uniqueNumbers.push(num)
        }
    }
    uniqueNumbers = uniqueNumbers.sort(function (a, b) {
        return a - b; // Ascending
    });
    let counter = 1
    let straightCards = [uniqueNumbers[0]]
    for (let i = 0; i < uniqueNumbers.length; i++) {
        if (uniqueNumbers[i + 1]) {
            let curr = uniqueNumbers[i]
            let next = uniqueNumbers[i + 1]

            if (curr + 1 == next) {
                straightCards.push(next)
                counter++
            } else if (next > curr + 1) {
                if (straightCards.length < 5) {
                    straightCards = []
                    straightCards.push(next)
                    counter = 1
                } else {
                    break
                }
            }
        }
    }

    if (uniqueNumbers.includes(14) && uniqueNumbers.includes(2) && uniqueNumbers.includes(3) && uniqueNumbers.includes(4) && uniqueNumbers.includes(5)) {
        straightCards = [2, 3, 4, 5, 14]
    }

    let alreadyIn = []
    let actualHand = []

    for (const c of cards) {
        let cVal = parseInt(c.slice(-2))

        if (straightCards.includes(cVal) && !alreadyIn.includes(cVal)) {
            actualHand.push(c)
            alreadyIn.push(cVal)
        }
    }


    return actualHand.sort(function (a, b) {
        let aVal = parseInt(a.slice(-2))
        let bVal = parseInt(b.slice(-2))
        return bVal - aVal; // Ascending
    }).slice(-5)

}

function checkPoints(result) {


    if (result.straightFlush.length >= 5) {
        return {points: 8, key: 'straightFlush', actualHand: result.straightFlush}
    } else if (result.moreThanOne.quads !== '') {
        return {points: 7, key: 'quads', actualHand: result.moreThanOne.hand}
    } else if (result.moreThanOne.firstTrips !== '' && (result.moreThanOne.secondTrips !== '' || result.moreThanOne.firstPair !== '')) {
        return {points: 6, key: 'fullHouse', actualHand: result.moreThanOne.hand}
    } else if (result.flush.length >= 5) {
        return {points: 5, key: 'flush', actualHand: result.flush}
    } else if (result.straight.length >= 5) {
        return {points: 4, key: 'straight', actualHand: result.straight}
    } else if (result.moreThanOne.firstTrips !== '') {
        return {points: 3, key: 'trips', actualHand: result.moreThanOne.hand}
    } else if (result.moreThanOne.firstPair !== '' && result.moreThanOne.secondPair !== '') {
        return {points: 2, key: 'twoPairs', actualHand: result.moreThanOne.hand}
    } else if (result.moreThanOne.firstPair !== '' && result.moreThanOne.secondPair == '') {
        return {points: 1, key: 'pair', actualHand: result.moreThanOne.hand}
    } else {
        return {points: 0, key: 'highCard', actualHand: result.moreThanOne.hand}
    }

}

function winLoseDraw(h) {
    let playerCards = h.hand.concat(h.board)
    let opponentCards = h.opponentHand.concat(h.board)

    //Player
    let moreThanOne = checkMoreThanOne(playerCards)
    let flush = checkFlush(playerCards)
    let straight = checkStraight(playerCards)

    let straightFlush = []
    if (straight.length >= 5 && flush.length >= 5) {

        straightFlush = checkStraight(flush)
    }

    //OPPONENT
    let opp_moreThanOne = checkMoreThanOne(opponentCards)
    let opp_flush = checkFlush(opponentCards)
    let opp_straight = checkStraight(opponentCards)

    let opp_straightFlush = []
    if (opp_straight.length >= 5 && flush.length >= 5) {
        let flushCards = opponentCards.filter((c) => {
            return c.charAt(0) === opp_flush
        })
        opp_straightFlush = checkStraight(flushCards)

    }


    let player = {moreThanOne, flush, straight, straightFlush}
    let opponent = {
        moreThanOne: opp_moreThanOne, flush: opp_flush, straight: opp_straight, straightFlush: opp_straightFlush
    }

    let playerBest = checkPoints(player)
    let opponentBest = checkPoints(opponent)

    let playerDumpedCards = playerCards.filter((c) => {
        return !playerBest.actualHand.includes(c)
    })
    let opponentDumpedCards = opponentCards.filter((c) => {
        return !opponentBest.actualHand.includes(c)
    })

    playerBest.dumpedCards = playerDumpedCards
    opponentBest.dumpedCards = opponentDumpedCards

    let outcome = "Draw"

    if (playerBest.points > opponentBest.points) {
        outcome = "Win"
    } else if (playerBest.points < opponentBest.points) {
        outcome = "Lose"
    } else if (playerBest.points == opponentBest.points) {

        let playerHandSorted = playerBest.actualHand

        let opponentHandSorted = opponentBest.actualHand


        for (let i = 0; i < 5; i++) {
            let playerVal = parseInt(playerHandSorted[i].slice(-2))
            let opponentVal = parseInt(opponentHandSorted[i].slice(-2))

            if (playerVal > opponentVal) {
                outcome = "Win"
                break
            } else if (playerVal < opponentVal) {
                outcome = "Lose"
            }
        }

        //Figure out "draw"
    }
    return {player: playerBest, opponent: opponentBest, outcome}
    return {player: playerBest, opponent: opponentBest, outcome, full: {player, opponent}}


}

async function runSequentially() {

    let maxGames = 100

    let freshDeck = getCopyOf(alternateDeck.sort())

    let handOneIndex = 0
    let handTwoIndex = 0
    let flopOneIndex = 0
    let flopTwoIndex = 0
    let flopThreeIndex = 0
    let turnIndex = 0
    let riverIndex = 0
    let opponentHandOneIndex = 0
    let opponentHandTwoIndex = 0

    let takenIndices = []

    let uniques = {}
    let counter = 0

    let starts = {a:0, b: 0, c: 0, d: 0, e: 0, f: 0, g: 0, h: 0, i: 0}
    for (let a = starts.a; a < freshDeck.length; a++) {

        let handOneIndex = a

        takenIndices.push(handOneIndex)

        let startB = a > starts.a ? a : starts.b

        for (let b = startB; b < freshDeck.length; b++) {
            if (takenIndices.includes(b)) {
                continue
            }

            handTwoIndex = b
            takenIndices = [a, b]

            let startC = b > starts.b ? 0 : starts.c

            for (let c = startC; c < freshDeck.length; c++) {
                if (takenIndices.includes(c)) {
                    continue
                }

                flopOneIndex = c
                takenIndices = [a, b, c]

                let startD = c > starts.c ? c : starts.d
                for (let d = startD; d < freshDeck.length; d++) {
                    if (takenIndices.includes(d)) {
                        continue
                    }

                    flopTwoIndex = d
                    takenIndices = [a, b, c, d]
                    let startE = d > starts.d ? d : starts.e
                    for (let e = startE; e < freshDeck.length; e++) {
                        if (takenIndices.includes(e)) {
                            continue
                        }

                        flopThreeIndex = e
                        takenIndices = [a, b, c, d, e]
                        let startF = e > starts.e ? 0 : starts.F
                        for (let f = startF; f < freshDeck.length; f++) {
                            if (takenIndices.includes(f)) {
                                continue
                            }

                            turnIndex = f
                            takenIndices = [a, b, c, d, e, f]
                            let startG = f > starts.f ? 0 : starts.g
                            for (let g = startG; g < freshDeck.length; g++) {
                                if (takenIndices.includes(g)) {
                                    continue
                                }

                                riverIndex = g
                                takenIndices = [a, b, c, d, e, f, g]
                                let startH = g > starts.g ? 0 : starts.h
                                for (let h = startH; h < freshDeck.length; h++) {
                                    if (takenIndices.includes(h)) {
                                        continue
                                    }

                                    opponentHandOneIndex = h
                                    takenIndices = [a, b, c, d, e, f, g, h]
                                    let startI = h > starts.h ? h : starts.i
                                    for (let i = startI; i < freshDeck.length; i++) {
                                        if (takenIndices.includes(i)) {
                                            continue
                                        }

                                        opponentHandTwoIndex = h
                                        takenIndices = [a, b, c, d, e, f, g, h, i]

                                        /***********************

                                         Run hand, check everything, add to uniques, etc.



                                         ************************/

                                        let hand = freshDeck[a] + freshDeck[b]
                                        let flop = freshDeck[c] + freshDeck[d] + freshDeck[e]
                                        let turn = freshDeck[f]
                                        let river = freshDeck[g]
                                        let opponent = freshDeck[h] + freshDeck[i]

                                        let ha = {
                                            handId: hand,
                                            opponentHandId: opponent,
                                            opponentHand: [freshDeck[h], freshDeck[i]],
                                            flopId: flop,
                                            turn: turn,
                                            river: river,
                                            board: [freshDeck[c], freshDeck[d], freshDeck[e], freshDeck[f], freshDeck[g]],
                                            hand: [freshDeck[a], freshDeck[b]],
                                        }


                                        let gamePath = 'poker/' + hand + '/' + flop + '/' + turn + '/' + river + '/' + opponent

                                        let remoteHand = await fb.getGame(gamePath)

                                        console.log("Hand: ")
                                        console.log(takenIndices)
                                        console.log(gamePath)


                                        if (remoteHand) {
                                            continue
                                        }
                                        console.log(counter)
                                        counter++

                                        let result = winLoseDraw(ha)


                                        if (!uniques[hand]) {

                                            uniques[hand] = {flops: {}, results: {wins: 0, losses: 0, draws: 0}}
                                        }

                                        if (!uniques[hand].flops[flop]) {
                                            uniques[hand].flops[flop] = {
                                                turns: {}, results: {wins: 0, losses: 0, draws: 0}
                                            }
                                        }
                                        if (!uniques[hand].flops[flop].turns[turn]) {
                                            uniques[hand].flops[flop].turns[turn] = {
                                                rivers: {}, results: {wins: 0, losses: 0, draws: 0}
                                            }
                                        }
                                        if (!uniques[hand].flops[flop].turns[turn].rivers[river]) {
                                            uniques[hand].flops[flop].turns[turn].rivers[river] = {
                                                opponentHands: {}, results: {wins: 0, losses: 0, draws: 0}
                                            }
                                        }
                                        if (!uniques[hand].flops[flop].turns[turn].rivers[river].opponentHands[opponent]) {
                                            uniques[hand].flops[flop].turns[turn].rivers[river].opponentHands[opponent] = {
                                                results: {
                                                    wins: 0, losses: 0, draws: 0
                                                }
                                            }
                                        } else {
                                            continue
                                        }

                                        if (result.outcome === "Win") {
                                            uniques[hand].results.wins++
                                            uniques[hand].flops[flop].results.wins++
                                            uniques[hand].flops[flop].turns[turn].results.wins++
                                            uniques[hand].flops[flop].turns[turn].rivers[river].results.wins++
                                            uniques[hand].flops[flop].turns[turn].rivers[river].opponentHands[opponent].results.wins++
                                        } else if (result.outcome === "Lose") {
                                            uniques[hand].results.losses++
                                            uniques[hand].flops[flop].results.losses++
                                            uniques[hand].flops[flop].turns[turn].results.losses++
                                            uniques[hand].flops[flop].turns[turn].rivers[river].results.losses++
                                            uniques[hand].flops[flop].turns[turn].rivers[river].opponentHands[opponent].results.losses++
                                        } else if (result.outcome === "Draw") {
                                            uniques[hand].results.draws++
                                            uniques[hand].flops[flop].results.draws++
                                            uniques[hand].flops[flop].turns[turn].results.draws++
                                            uniques[hand].flops[flop].turns[turn].rivers[river].results.draws++
                                            uniques[hand].flops[flop].turns[turn].rivers[river].opponentHands[opponent].results.draws++
                                        } else {
                                            console.log(h)
                                        }

                                        if (counter >= maxGames) {
                                            console.log("End of the road")
                                            break
                                        }

                                    }
                                    if (counter >= maxGames) {
                                        console.log("End of the road")
                                        break
                                    }

                                }
                                if (counter >= maxGames) {
                                    console.log("End of the road")
                                    break
                                }
                            }
                            if (counter >= maxGames) {
                                console.log("End of the road")
                                break
                            }

                        }
                        if (counter >= maxGames) {
                            console.log("End of the road")
                            break
                        }
                    }
                    if (counter >= maxGames) {
                        console.log("End of the road")
                        break
                    }
                }
                if (counter >= maxGames) {
                    console.log("End of the road")
                    break
                }
            }
            if (counter >= maxGames) {
                console.log("End of the road")
                break
            }
        }
        if (counter >= maxGames) {
            console.log("End of the road")
            break
        }
    }
    console.log("Ran " + counter + " unique games")

}

function buildHandVariations(hand, opponentHand, board, startingHand = null) {

    let handVariants = []

    let boardVariants = []

    boardVariants.push( [board[0],board[1],board[2],board[3],board[4]] )
    boardVariants.push( [board[0],board[1],board[2],board[4],board[3]] )

    boardVariants.push( [board[0],board[1],board[3],board[2],board[4]] )
    boardVariants.push( [board[0],board[1],board[3],board[4],board[2]] )

    boardVariants.push( [board[0],board[1],board[4],board[2],board[3]] )
    boardVariants.push( [board[0],board[1],board[4],board[3],board[2]] )

    boardVariants.push( [board[0],board[2],board[3],board[1],board[4]] )
    boardVariants.push( [board[0],board[2],board[3],board[4],board[1]] )

    boardVariants.push( [board[0],board[2],board[4],board[1],board[3]] )
    boardVariants.push( [board[0],board[2],board[4],board[3],board[1]] )

    boardVariants.push( [board[0],board[3],board[4],board[1],board[2]] )
    boardVariants.push( [board[0],board[3],board[4],board[2],board[1]] )

    boardVariants.push( [board[1],board[2],board[3],board[0],board[4]] )
    boardVariants.push( [board[1],board[2],board[3],board[4],board[0]] )

    boardVariants.push( [board[1],board[2],board[4],board[0],board[3]] )
    boardVariants.push( [board[1],board[2],board[4],board[3],board[0]] )

    boardVariants.push( [board[1],board[3],board[4],board[0],board[2]] )
    boardVariants.push( [board[1],board[3],board[4],board[2],board[0]] )

    boardVariants.push( [board[2],board[3],board[4],board[0],board[1]] )
    boardVariants.push( [board[2],board[3],board[4],board[1],board[0]] )

    for(const bv of boardVariants) {

        let handId = hand.join("")
        let opponentId = opponentHand.join("")
        let flop = [bv[0], bv[1], bv[2]]
        let flopId = flop.join("")
        let turn = bv[3]
        let river = bv[4]

        let uniqueOneId = handId + '-' + flopId + turn + river + '-' + opponentId
        let uniqueTwoId = opponentId + '-' + flopId + turn + river + '-' + handId

        let hvOne = {uniqueId: uniqueOneId, 
                     hand: hand,
                     handId: handId,
                     flop,
                     flopId,
                     turn, 
                     river,
                     board: bv,
                     boardId: bv.join(""),
                     opponentHandId: opponentId, 
                     opponentHand: opponentHand
                     }

        let hvTwo = {uniqueId: uniqueOneId, 
                     hand: opponentHand,
                     handId: opponentId,
                     flop,
                     flopId,
                     turn, 
                     river,
                     board: bv,
                     boardId: bv.join(""),
                     opponentHandId: handId, 
                     opponentHand: hand
                     }
        handVariants.push(hvOne)
        if(!startingHand) {
            handVariants.push(hvTwo)
        }

    }

    return handVariants
}


export async function runWithStartingHand(nrOfHands = 1, startingHand) {


    console.log(moment().format('YY-MM-DD HH:mm:ss'))

    let path = 'pokerTwo/'
    if(startingHand) {
        path = path+ startingHand[0] + "/"+ startingHand[1]
    }
    let uniques = await fb.getNodes(path) || {flops: {}, results: {wins: 0, losses: 0, draws: 0}}

    console.log(moment().format('YY-MM-DD HH:mm:ss'))
    
    let uniquePlayerHands = 0

    let hands = []

    for(let i = 0; i < nrOfHands; i++ ) {
        let h = runOneHand(startingHand)
        let variants = buildHandVariations(h.hand, h.opponentHand, h.board, startingHand)
        hands = hands.concat(variants)
    }

    let index = 0
    for (const h of hands) {


        let game = {}

        let hand = h.handId
        let flop = h.flopId
        let flopCards = h.flop
        let turn = h.turn
        let river = h.river
        let opponent = h.opponentHandId
        debugger
        let gamePath = 'poker/' + hand + '/' + flop + '/' + turn + '/' + river + '/' + opponent

        /*let remoteHand = await fb.getGame(gamePath)


        if (remoteHand) {
            continue
        }*/


        let result = winLoseDraw(h)
        h.result = result

        let flopCardOne = flopCards[0]
        let flopCardTwo = flopCards[1]
        let flopCardThree = flopCards[2]
        let oppHandOne = h.opponentHand[0]
        let oppHandTwo = h.opponentHand[1]

        if(!uniques.flops[flopCardOne]) {
            uniques.flops[flopCardOne] = {}
        }
        if(!uniques.flops[flopCardOne][flopCardTwo]) {
            uniques.flops[flopCardOne][flopCardTwo] = {}
        }
        if(!uniques.flops[flopCardOne][flopCardTwo][flopCardThree]) {
            uniques.flops[flopCardOne][flopCardTwo][flopCardThree] = {results: {wins: 0, losses: 0, draws: 0}, turns: {}}
        }
        if(!uniques.flops[flopCardOne][flopCardTwo][flopCardThree].turns[turn]) {
            uniques.flops[flopCardOne][flopCardTwo][flopCardThree].turns[turn] = {results: {wins: 0, losses: 0, draws: 0}, rivers: {}}
        }
        if(!uniques.flops[flopCardOne][flopCardTwo][flopCardThree].turns[turn].rivers[river]) {
            uniques.flops[flopCardOne][flopCardTwo][flopCardThree].turns[turn].rivers[river] = {results: {wins: 0, losses: 0, draws: 0}, opponentHandOne: {}}
        }
        if(!uniques.flops[flopCardOne][flopCardTwo][flopCardThree].turns[turn].rivers[river].opponentHandOne[oppHandOne]) {
            uniques.flops[flopCardOne][flopCardTwo][flopCardThree].turns[turn].rivers[river].opponentHandOne[oppHandOne] = {}
        }
        if(!uniques.flops[flopCardOne][flopCardTwo][flopCardThree].turns[turn].rivers[river].opponentHandOne[oppHandOne][oppHandTwo]) {
            uniques.flops[flopCardOne][flopCardTwo][flopCardThree].turns[turn].rivers[river].opponentHandOne[oppHandOne][oppHandTwo] = {results: {wins:0, losses: 0, draws: 0}}
        } else {
            console.log("Hand exists")
            continue
        }


        if (result.outcome === "Win") {
            uniques.results.wins++
            uniques.flops[flopCardOne][flopCardTwo][flopCardThree].results.wins++
            uniques.flops[flopCardOne][flopCardTwo][flopCardThree].turns[turn].results.wins++
            uniques.flops[flopCardOne][flopCardTwo][flopCardThree].turns[turn].rivers[river].results.wins++
            uniques.flops[flopCardOne][flopCardTwo][flopCardThree].turns[turn].rivers[river].opponentHandOne[oppHandOne][oppHandTwo].results.wins++
        } else if (result.outcome === "Lose") {
            uniques.results.losses++
            uniques.flops[flopCardOne][flopCardTwo][flopCardThree].results.losses++
            uniques.flops[flopCardOne][flopCardTwo][flopCardThree].turns[turn].results.losses++
            uniques.flops[flopCardOne][flopCardTwo][flopCardThree].turns[turn].rivers[river].results.losses++
            uniques.flops[flopCardOne][flopCardTwo][flopCardThree].turns[turn].rivers[river].opponentHandOne[oppHandOne][oppHandTwo].results.losses++
        } else if (result.outcome === "Draw") {
            uniques.results.draws++
            uniques.flops[flopCardOne][flopCardTwo][flopCardThree].results.draws++
            uniques.flops[flopCardOne][flopCardTwo][flopCardThree].turns[turn].results.draws++
            uniques.flops[flopCardOne][flopCardTwo][flopCardThree].turns[turn].rivers[river].results.draws++
            uniques.flops[flopCardOne][flopCardTwo][flopCardThree].turns[turn].rivers[river].opponentHandOne[oppHandOne][oppHandTwo].results.draws++
        } else {
            console.log(h)
        }

        if (index % 1000 == 0) {
            console.log(index)
        }
        index++

    }

    console.log("Writing results")
    console.log(moment().format('YYYY-MM-DD HH:mm:ss'))
    await firebasing2(uniques, path)
    console.log(moment().format('YYYY-MM-DD HH:mm:ss'))
}

export async function run(nrOfHands = 1) {


    let flops = {}
    let boards = {}
    let fulls = {}

    console.log(moment().format('YY-MM-DD HH:mm:ss'))

    let path = 'pokerTwo/'
    
    let uniques = await fb.getNodes(path)

    console.log(moment().format('YY-MM-DD HH:mm:ss'))

    debugger
    let uniquePlayerHands = 0

    let hands = []

    for(let i = 0; i < nrOfHands; i++ ) {
        let h = runOneHand()
        let variants = buildHandVariations(h.hand, h.opponentHand, h.board)
        hands = hands.concat(variants)
        debugger
    }

    let index = 0
    for (const h of hands) {


        let game = {}

        let hand = h.handId
        let flop = h.flopId
        let turn = h.turn
        let river = h.river
        let opponent = h.opponentHandId

        let gamePath = 'poker/' + hand + '/' + flop + '/' + turn + '/' + river + '/' + opponent

        /*let remoteHand = await fb.getGame(gamePath)


        if (remoteHand) {
            continue
        }*/


        let result = winLoseDraw(h)
        h.result = result


        if (!uniques[hand]) {

            uniques[hand] = {flops: {}, results: {wins: 0, losses: 0, draws: 0}}
        }

        if (!uniques[hand].flops[flop]) {
            uniques[hand].flops[flop] = {turns: {}, results: {wins: 0, losses: 0, draws: 0}}
        }
        if (!uniques[hand].flops[flop].turns[turn]) {
            uniques[hand].flops[flop].turns[turn] = {rivers: {}, results: {wins: 0, losses: 0, draws: 0}}
        }
        if (!uniques[hand].flops[flop].turns[turn].rivers[river]) {
            uniques[hand].flops[flop].turns[turn].rivers[river] = {
                opponentHands: {}, results: {wins: 0, losses: 0, draws: 0}
            }
        }
        if (!uniques[hand].flops[flop].turns[turn].rivers[river].opponentHands[opponent]) {
            uniques[hand].flops[flop].turns[turn].rivers[river].opponentHands[opponent] = {
                results: {
                    wins: 0, losses: 0, draws: 0
                }
            }
        } else {
            console.log("Hand Exists")
            continue
        }

        if (result.outcome === "Win") {
            uniques[hand].results.wins++
            uniques[hand].flops[flop].results.wins++
            uniques[hand].flops[flop].turns[turn].results.wins++
            uniques[hand].flops[flop].turns[turn].rivers[river].results.wins++
            uniques[hand].flops[flop].turns[turn].rivers[river].opponentHands[opponent].results.wins++
        } else if (result.outcome === "Lose") {
            uniques[hand].results.losses++
            uniques[hand].flops[flop].results.losses++
            uniques[hand].flops[flop].turns[turn].results.losses++
            uniques[hand].flops[flop].turns[turn].rivers[river].results.losses++
            uniques[hand].flops[flop].turns[turn].rivers[river].opponentHands[opponent].results.losses++
        } else if (result.outcome === "Draw") {
            uniques[hand].results.draws++
            uniques[hand].flops[flop].results.draws++
            uniques[hand].flops[flop].turns[turn].results.draws++
            uniques[hand].flops[flop].turns[turn].rivers[river].results.draws++
            uniques[hand].flops[flop].turns[turn].rivers[river].opponentHands[opponent].results.draws++
        } else {
            console.log(h)
        }

        if (index % 500 == 0) {
            console.log(index)
        }
        index++

    }

    console.log("Writing results")
    console.log(moment().format('YYYY-MM-DD HH:mm:ss'))
    await firebasing2(uniques)
    console.log(moment().format('YYYY-MM-DD HH:mm:ss'))

    console.log("All Done")
}


//Tests

let tests = {
    testPair: {
        name: "Pairs", hand: ['C14', 'D07'], opponentHand: ['C2', 'C3'], board: ['D14', 'H04', 'H05', 'S10', 'S11']
    }, testTwoPair: {
        name: "Two Pairs",
        hand: ['C14', 'D07'],
        opponentHand: ['C02', 'C03'],
        board: ['D14', 'H05', 'H07', 'S10', 'S11']
    }, testTrips: {
        name: "Trips", hand: ['C14', 'D07'], opponentHand: ['C02', 'C03'], board: ['D13', 'H05', 'H07', 'S07', 'S11']
    }, testTwoTrips: {
        name: "Two Trips",
        hand: ['C14', 'D07'],
        opponentHand: ['C02', 'C03'],
        board: ['D14', 'H05', 'H07', 'S07', 'S14']
    }, testQuads: {
        name: "Quads", hand: ['C14', 'D07'], opponentHand: ['C02', 'C03'], board: ['D14', 'H05', 'H14', 'S08', 'S14']
    }, testFullHouse: {
        name: "Full House",
        hand: ['C14', 'D13'],
        opponentHand: ['C02', 'C03'],
        board: ['D14', 'H05', 'H13', 'S08', 'S13']
    }, testQuadAndTrip: {
        name: "Quad And Trip",
        hand: ['C14', 'D13'],
        opponentHand: ['C02', 'C03'],
        board: ['D14', 'H13', 'H14', 'S13', 'S14']
    }, testLowStraight: {
        name: "Low Straight",
        hand: ['C14', 'D02'],
        opponentHand: ['C02', 'C03'],
        board: ['C03', 'H04', 'H13', 'S05', 'S13']
    }, testMidStraight: {
        name: "MidStraight",
        hand: ['C07', 'D02'],
        opponentHand: ['C02', 'C03'],
        board: ['C08', 'H09', 'H13', 'S10', 'S11']
    }, testHighStraight: {
        name: "HighStraight",
        hand: ['C10', 'D11'],
        opponentHand: ['C02', 'C03'],
        board: ['C08', 'H12', 'H13', 'S10', 'S14']
    }, testSixCardStraight: {
        name: "Six Card Straight",
        hand: ['C10', 'D11'],
        opponentHand: ['C02', 'C03'],
        board: ['C09', 'H12', 'H13', 'S10', 'S14']
    }, testSevenCardStraight: {
        name: "Seven Card Straight",
        hand: ['C10', 'D11'],
        opponentHand: ['C02', 'C03'],
        board: ['C09', 'H12', 'H13', 'S08', 'S14']
    }, testFlush: {
        name: "Flush", hand: ['C08', 'C11'], opponentHand: ['C02', 'C03'], board: ['C04', 'C05', 'C13', 'S09', 'S14']
    }, testStraightAndFlush: {
        name: "Straight And Flush",
        hand: ['C06', 'C11'],
        opponentHand: ['C02', 'C03'],
        board: ['C04', 'C09', 'C13', 'S10', 'S12']
    }, testStraightFlush: {
        name: "StraightFlush",
        hand: ['C05', 'C06'],
        opponentHand: ['C02', 'C03'],
        board: ['C04', 'C07', 'C08', 'S10', 'S12']
    }, bothFlush: {
        name: "Both Flush",
        hand: ['C05', 'C06'],
        opponentHand: ['C02', 'C03'],
        board: ['C08', 'C09', 'C10', 'S10', 'S12']
    }, sevenCardFlush: {
        name: "Seven card Flush",
        hand: ['C10', 'C13'],
        opponentHand: ['C02', 'C03'],
        board: ['C04', 'C07', 'C08', 'C11', 'C14']
    },

    randomTest1: {
        name: "Random Test 1",
        hand: ['C06', 'H13'],
        opponentHand: ['D03', 'S03'],
        board: ['C13', 'H07', 'S02', 'S08', 'S12'],
        expectedOutcome: "Win"
    }, randomTest2: {
        name: "Random Test 2",
        hand: ['C08', 'H14'],
        opponentHand: ['H04', 'S02'],
        board: ['C11', 'D06', 'D08', 'D09', 'H08'],
        expectedOutcome: "Win"
    }, randomTest3: {
        name: "Random Test 3",
        hand: ['C08', 'H13'],
        opponentHand: ['H02', 'H09'],
        board: ['C04', 'C06', 'H14', 'S02', 'S08'],
        expectedOutcome: "Win"
    }, randomTest4: {
        name: "Random Test 4",
        hand: ['H07', 'H14'],
        opponentHand: ['H12', 'S04'],
        board: ['C04', 'D09', 'H02', 'H09', 'S03'],
        expectedOutcome: "Lose"
    }, randomTest5: {
        name: "Random Test 5",
        hand: ['H03', 'S13'],
        opponentHand: ['H02', 'S09'],
        board: ['C06', 'C08', 'D07', 'H09', 'S11'],
        expectedOutcome: "Lose"
    }, randomTest6: {
        name: "Random Test 6",
        hand: ['D13', 'S05'],
        opponentHand: ['D05', 'H08'],
        board: ['C05', 'C11', 'C14', 'D03', 'S09'],
        expectedOutcome: "Win"
    }, randomTest7: {
        name: "Random Test 7",
        hand: ['C09', 'C14'],
        opponentHand: ['C14', 'H10'],
        board: ['D09', 'H11', 'H14', 'S03', 'S14'],
        expectedOutcome: "Win"
    }, randomTest8: {
        name: "Random Test 8",
        hand: ['H13', 'S09'],
        opponentHand: ['D08', 'S11'],
        board: ['C04', 'D02', 'D07', 'D13', 'H03'],
        expectedOutcome: "Win"
    }, randomTest9: {
        name: "Random Test 9",
        hand: ['D06', 'S03'],
        opponentHand: ['C04', 'H08'],
        board: ['C09', 'C10', 'H11', 'S02', 'S07'],
        expectedOutcome: "Lose"
    }, randomTest10: {
        name: "Random Test 10",
        hand: ['H14', 'S09'],
        opponentHand: ['C02', 'C07'],
        board: ['D02', 'C05', 'C07', 'H09', 'H10'],
        expectedOutcome: "Lose"
    },

}


function runTests() {
    for (const key of Object.keys(tests)) {
        //console.log(key)
        let t = tests[key]
        //console.log(t.name)
        let result = winLoseDraw(t)
        //console.log(result)
        if (t.expectedOutcome != null && result.outcome !== t.expectedOutcome) {
            console.log("FEL RESULTAT!")
        }
    }
}

export async function runExternal(t = null) {


    let time = parseInt(t)
    let start = moment()
    let stop = moment().add(time,'minutes')
    console.log("starting at " +start.format('YYYY-MM-DD HH:mm:ss'))

    let nrOfTimes = 1
    let nrOfHands = 100


    if(time && time > 0) {

        let startMeta = await fb.getNodes("pokerTwoMeta/")
        let firstCounter = startMeta?.first || 0
        let secondCounter = startMeta?.second || 1
        let laps = startMeta?.laps || 0
        let totalHands = startMeta?.totalHands || 0
        let count = 0

        while(moment().isBefore(stop)) {
            count++
            let freshDeck = getCopyOf(alternateDeck).sort()

            let startingHand = [freshDeck[firstCounter], freshDeck[secondCounter]]
            console.log(startingHand)
            await runWithStartingHand(nrOfHands, startingHand)
            totalHands+=nrOfHands*20

            if(secondCounter < 51) {
                secondCounter++
            } else if(firstCounter < 50) {
                firstCounter++
                secondCounter = firstCounter+1
            } else {
                firstCounter = 0
                secondCounter = 1
                laps++
            }


        }
        let meta = {first: firstCounter, second: secondCounter, totalHands, laps,  time: moment().format('YYYY-MM-DD HH:mm:ss')}
        await fb.writeNodes("pokerTwoMeta/", meta)

        let end = moment().format('YY-MM-DD HH:mm:ss')
        console.log("ran " + count + " times and " + nrOfHands + " hands per run, between " + start.format('YY-MM-DD HH:mm:ss') + " and "+ end)
        
    } else {

      
        for (let i = 0; i < nrOfTimes; i++) {
            await run(nrOfHands, false)
            console.log(i)
        }

        let end = moment().format('YY-MM-DD HH:mm:ss')
        console.log("ran " + nrOfTimes + " and " + nrOfHands + " hands per run, between " + start.format('YY-MM-DD HH:mm:ss') + " and "+ end)
    }

}

export async function runSeq() {

    let nrOfTimes = 250
    let nrOfHands = 400

    await runSequentially()

}






