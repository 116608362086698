//firebaseHandler.js


import {initializeApp} from "firebase/app"
import {getDatabase,connectDatabaseEmulator, ref, set, child, get, orderByKey, once} from "firebase/database"

import {getCopyOf} from './utilityFunctions'
import CryptoJS from 'react-native-crypto-js';


import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut
} from "firebase/auth";
import {getFirestore, query, getDocs, collection, where, addDoc} from "firebase/firestore";


const firebaseConfig = {
    apiKey: "AIzaSyCl3_zVh1cZhJ5fyC7t-Oaqi9HOASHLC-8",
    authDomain: "gragripen-mit.firebaseapp.com",
    databaseURL: "https://gragripen-mit-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "gragripen-mit",
    storageBucket: "gragripen-mit.appspot.com",
    messagingSenderId: "582329223549",
    appId: "1:582329223549:web:58b5bb7ddd722283b72414"
}

const enc = "ZSOFusafUaB6xCfloF+2WTMtGo1FDRsI2Ni7pfXy6DnS3eUSoiyDKhA4pKNMzvPa"


export async function getAccount(userId) {


    const dbRef = ref(getDatabase());
    let account
    await get(child(dbRef, `accounts/` + userId)).then((snapshot) => {
        if (snapshot.exists()) {
            account = snapshot.val()

        } else {

            return ""
        }
    }).catch((error) => {
        return ""
        console.error(error);
    });
    if (account) {

        let acc = getCopyOf(account)

        if (!acc.personal) {

            acc.personal = {}
        }

        for (const key of Object.keys(acc.personal)) {


            let cryptKey = enc + acc.id

            let newVal = CryptoJS.AES.decrypt(acc.personal[key], cryptKey).toString(CryptoJS.enc.Utf8)

            acc.personal[key] = newVal

        }

        return acc
    }
}

export async function getCharacters() {

    const dbRef = ref(getDatabase());
    let characters
    await get(child(dbRef, `characters`)).then((snapshot) => {
        if (snapshot.exists()) {
            characters = snapshot.val()

        } else {

            return []
        }
    }).catch((error) => {
        return []
        console.error(error);
    });
    if (characters) {
        return characters
    }

}

export async function getModerators() {

    const dbRef = ref(getDatabase());
    let characters
    await get(child(dbRef, `moderators`)).then((snapshot) => {
        if (snapshot.exists()) {
            characters = snapshot.val()

        } else {

            return []
        }
    }).catch((error) => {
        return []
        console.error(error);
    });
    if (characters) {
        return characters
    }

}

export async function getUtil(util) {

    const dbRef = ref(getDatabase());
    let characters
    await get(child(dbRef, `utils/` + util)).then((snapshot) => {
        if (snapshot.exists()) {
            characters = snapshot.val()

        } else {

            return []
        }
    }).catch((error) => {
        return []
        console.error(error);
    });
    if (characters) {
        return characters
    }

}

export async function getLarps() {

    const dbRef = ref(getDatabase());
    let larps
    await get(child(dbRef, `larps`)).then((snapshot) => {
        if (snapshot.exists()) {

            larps = snapshot.val()

        } else {

            return {}
        }
    }).catch((error) => {
        console.error(error);
        return {}
    });
    if (larps) {
        return larps
    }

}

export async function getAccounts() {

    const dbRef = ref(getDatabase());
    let characters
    await get(child(dbRef, `accounts`)).then((snapshot) => {
        if (snapshot.exists()) {
            characters = snapshot.val()

        } else {

            return []
        }
    }).catch((error) => {
        return []
        console.error(error);
    });
    if (characters) {
        return characters
    }

}

export async function getShallowAccounts() {



    const dbRef = ref(getDatabase());


    let characters = []

await get(child(dbRef, 'accounts'), { shallow: true }).then(snapshot => {

  debugger  
  snapshot.forEach(childSnapshot => {
    const key = childSnapshot.key; // Get the key (child node name)
    debugger
    characters.push(""+key);  // Add the key to your array
    console.log(key);  // Log the key
  });
}).catch(error => {
  console.error("Error fetching data: ", error);
});


    if(characters) {
        return characters
    } else {
        return []
    }


}

export async function getCharacterEvents() {

    const dbRef = ref(getDatabase());
    let events
    await get(child(dbRef, `events`)).then((snapshot) => {
        if (snapshot.exists()) {
            events = snapshot.val()

        } else {

            return []
        }
    }).catch((error) => {
        return []
        console.error(error);
    });
    if (events) {
        return events
    }

}

export async function getFiefs() {

    const dbRef = ref(getDatabase());
    let fiefs
    await get(child(dbRef, `fiefs`)).then((snapshot) => {
        if (snapshot.exists()) {
            fiefs = snapshot.val()

        } else {

            return []
        }
    }).catch((error) => {
        return []
        console.error(error);
    });
    if (fiefs) {
        return fiefs
    }

}

export async function getFief(id) {
    const dbRef = ref(getDatabase());
    let fief
    await get(child(dbRef, `fiefs/` + id)).then((snapshot) => {
        if (snapshot.exists()) {
            fief = snapshot.val()

        } else {
            console.error("No fief found")
            return {}
        }
    }).catch((error) => {

        console.error(error);
        return {}
    });
    if (fief) {
        return fief
    }
}

export async function getLogForFief(fiefId) {
    const dbRef = ref(getDatabase());
    let log
    await get(child(dbRef, `logs/` + fiefId)).then((snapshot) => {
        if (snapshot.exists()) {
            log = snapshot.val()

        } else {
            console.error("empty");

            return []
        }
    }).catch((error) => {
        console.error(error);
        return []
    });
    if (log) {
        return log
    }
}


export async function writeAccount(account) {

    let acc = getCopyOf(account)


    let cryptKey = enc + acc.id

    for (const key of Object.keys(acc.personal)) {
        let val = acc.personal[key]
        acc.personal[key] = CryptoJS.AES.encrypt(val, cryptKey).toString();
    }

    debugger
    const db = getDatabase()

    await set(ref(db, 'accounts/' + acc.uid), acc)

}

export async function writeLarp(larp) {


    const db = getDatabase()

    await set(ref(db, 'larps/' + larp.id), larp)

}

export async function writeEvent(event) {


    const db = getDatabase()

    await set(ref(db, 'events/' + event.id), event)

}

export async function writeUtils(utilName, util) {


    const db = getDatabase()

    await set(ref(db, 'utils/' + utilName), util)

}

export async function writeCharacter(character) {

    delete character.events


    const db = getDatabase()

    await set(ref(db, 'characters/' + character.id), character)

}


export async function writeFief(fief, trimmedLog) {

    let f = getCopyOf(fief)

    let log = getCopyOf(trimmedLog)


    console.log("writing fief")


    const db = getDatabase()

    await set(ref(db, 'fiefs/' + f.id), f)

    console.log("wrote fief")

    for (let i = 0; i < Object.keys(log).length; i++) {
        let key = Object.keys(log)[i]
        let logItem = log[key]
        await set(ref(db, 'logs/' + fief.id + '/' + i), logItem)

    }

    console.log("wrote log")

}


export async function writeBusiness(business) {
    const db = getDatabase()

    await set(ref(db, 'business/' + business.id), business)
}


export async function writeBusinessMonth(bm) {


    let id = bm.year + "-" + bm.month
    const db = getDatabase()

    await set(ref(db, 'businessMonth/' + bm.businessId + '/' + id), bm)

}


export async function getGame(path) {

    const dbRef = ref(getDatabase());
    let game
    await get(child(dbRef, path)).then((snapshot) => {
        if (snapshot.exists()) {
            game = snapshot.val()

        } else {
            debugger
            return {}
        }
    }).catch((error) => {

        console.error(error);
        return {}
    });
    if (game) {
        return game
    }

}

export async function checkNodeExists(path) {

    const dbRef = ref(getDatabase());
    let game
    await get(child(dbRef, path+'/exists')).then((snapshot) => {
        if (snapshot.exists()) {
            game = snapshot.val()

        } else {

            return {}
        }
    }).catch((error) => {

        console.error(error);
        return {}
    });
    if (game) {
        return game
    }

}
export async function checkNodeWins(path) {

    const dbRef = ref(getDatabase());
    let game
    await get(child(dbRef, path+'/wins')).then((snapshot) => {
        if (snapshot.exists()) {
            game = snapshot.val()

        } else {

            return {}
        }
    }).catch((error) => {

        console.error(error);
        return {}
    });
    if (game) {
        return game
    }

}
export async function checkNodeResults(path) {

    const dbRef = ref(getDatabase());
    let game
    await get(child(dbRef, path+'/results')).then((snapshot) => {
        if (snapshot.exists()) {
            game = snapshot.val()

        } else {

            return {}
        }
    }).catch((error) => {

        console.error(error);
        return {}
    });
    if (game) {
        return game
    }

}

export async function writeFullGame(path, game) {

    const db = getDatabase()
    await set(ref(db, path), game)
}


export async function getNodes(path) {

    const dbRef = ref(getDatabase());
    let nodes
    await get(child(dbRef, path)).then((snapshot) => {
        if (snapshot.exists()) {
            nodes = snapshot.val()

        } else {

            return {}
        }
    }).catch((error) => {

        console.error(error);
        return {}
    });
    if (nodes) {
        return nodes
    }
}

export async function writeNodes(path, game) {

    const db = getDatabase()
    await set(ref(db, path), game)
}

export async function setExistOnNode(path) {

    const db = getDatabase()
    await set(ref(db, path+'/exists'), true)
}

export async function updateResults(path, results) {
    const db = getDatabase()


    await set(ref(db, path+'/results'), results)


}

export async function writePokerMeta(path,meta) {

    const db = getDatabase()
    await set(ref(db, path), meta)
}

export async function getPokerResults(path) {

    let newPath = 'poker/'+path+"/results"

    
    console.log(newPath)

    let db = getDatabase()


    const dbRef = ref(db);

    let game
    await get(child(dbRef, newPath)).then((snapshot) => {
        if (snapshot.exists()) {
            game = snapshot.val()

        } else {

            return {}
        }
    }).catch((error) => {

        console.error(error);
        return {}
    });
    if (game) {
        console.log("Found results:" + game)
        return game
    }

}

export async function getGriffonTree() {

    let newPath = 'griffonTree/'

    
    console.log(newPath)

    let db = getDatabase()


    const dbRef = ref(db);

    let tree
    await get(child(dbRef, newPath)).then((snapshot) => {
        if (snapshot.exists()) {
            tree = snapshot.val()

        } else {

            return {}
        }
    }).catch((error) => {

        console.error(error);
        return {}
    });
    if (tree) {
        return tree
    }

}











