//CharacterEditor.js


import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {Dropdown, Button, Checkbox, Input, Table, Tab, List, Image} from "semantic-ui-react";
import './CharacterViewer.css'
import {SocialCard, CharacterItem} from './SocialCard'
import LoggedInHeader from './LoggedInHeader'
import Resizer from "react-image-file-resizer";


import * as constants from './constants'

import {useStore, updateTheStore} from './newState'
import {v4 as uuid} from "uuid";
import * as fb from "./firebaseHandler";
import React from "react";
import {
    ref,
    uploadBytesResumable, getDownloadURL
} from "firebase/storage";

async function saveCharacter(existingCharacter = null) {


    let testState = useStore.getState()

    let auth = testState.auth

    let name = document.getElementById("char_name").value
    let pronouns = document.getElementById("char_pronouns").value
    let birthYear = document.getElementById("char_birthyear").value
    let origin = document.getElementById("char_origin").value
    let description = document.getElementById("char_desc").value
    let npc = document.getElementById("char_npc").checked
    let blurb = document.getElementById("char_blurb").value
    let profileImage = testState.characterHandler.uploadedImageUrl !== "" ? testState.characterHandler.uploadedImageUrl : existingCharacter?.profileImage
    let profileThumb = testState.characterHandler.uploadedThumbUrl !== "" ? testState.characterHandler.uploadedThumbUrl : existingCharacter?.profileThumb
    let dead = document.getElementById("char_dead").checked || false

    if(!profileImage) {
        profileImage = ""
    }
    if(!profileThumb) {
        profileThumb = ""
    }


    let notDone = false

    if(name === "") {
        document.getElementById("char_name").style.backgroundColor = "red"
        notDone = true
    }
    if(pronouns === "") {
        document.getElementById("char_pronouns").style.backgroundColor = "red"
        notDone = true
    }
    if(birthYear === "") {
        document.getElementById("char_birthyear").style.backgroundColor = "red"
        notDone = true
    }

    if(origin === "") {
        document.getElementById("char_origin").style.backgroundColor = "red"
        notDone = true
    }

    if(blurb === "") {
        document.getElementById("char_blurb").style.backgroundColor = "red"
        notDone = true
    }

    if(notDone) {
        return
    }


    let owner = existingCharacter?.owner || auth?.currentUser?.uid
    let categories = existingCharacter?.categories || []

    if (!owner) {
        return
    }

    let character = {
        id: existingCharacter.id, name, pronouns, origin, birthYear,
        description, npc, owner, blurb, profileImage, profileThumb, dead, isPublic: existingCharacter.isPublic, categories
    }


    await fb.writeCharacter(character)

    let characters = await fb.getCharacters()

    updateTheStore(s => {
        s.characterHandler.characters = characters
    })

    updateTheStore(s => {
        s.characterHandler.editingACharacter = false
    })
    updateTheStore(s => {
        s.characterHandler.viewCharacter = null
    })

    updateTheStore(s => {
        s.characterHandler.uploadedImageUrl = ""
    })

}

function goBack() {

    updateTheStore(s => {
        s.characterHandler.editingACharacter = false
    })

    updateTheStore(s => {
        s.characterHandler.uploadedImageUrl = ""
    })
    updateTheStore(s => {
        s.characterHandler.uploadedFile = null
    })

}

async function uploadImage(file) {

    let smallFile = await getThumbnail(file.target.files[0])
    updateTheStore(s => {
        s.characterHandler.uploadedFile = file.target.files[0]
    })
    updateTheStore(s => {
        s.characterHandler.uploadedThumb = smallFile
    })
}

async function getThumbnail(file) {

const resizeFile = (file, type) =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        type,
        100,
        0,
        uri => {
          console.log(uri);
          resolve(uri);
        }
      );
    });

const uri = await resizeFile(file, file.type.split("/")[1]);
 const image = new Image([uri], {type: file.type});
 let newFile = await fetch(uri).then(r => r.blob()).then(blobFile => new File([blobFile], file.name, { type: file.type }))

 return newFile
}

async function uploadImageToFirebase() {


    let testState = useStore.getState()

    let character = testState.characterHandler.viewCharacter

    let storage = testState.storage

    let image = testState.characterHandler.uploadedFile

    let thumb = testState.characterHandler.uploadedThumb

    if (!image) {
        alert("Please choose a file first!")
        return
    }

    //Big Image
    let name = character.id + '_profileImage'
    const storageRef = ref(storage, `/files/${name}`)
    const uploadTask = uploadBytesResumable(storageRef, image);

    uploadTask.on(
        "state_changed",
        (snapshot) => {
            const percent = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );

            // update progress
            updateTheStore(s => {
                s.characterHandler.percent = percent
            })
        },
        (err) => console.log(err),
        () => {
            // download url
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                updateTheStore(s => {
                    s.characterHandler.uploadedImageUrl = url
                })
                updateTheStore(s => {
                    s.characterHandler.percent = 0
                })
                updateTheStore(s => {
                    s.characterHandler.uploadedFile = null
                })
                console.log(url);

            });
        }
    );

    //Thumbnail


   

    if(thumb) {
    let thumbName = name+'_thumbnail'
    debugger
    const thumbStorageRef = ref(storage, `/files/${thumbName}`)
    const thumbUploadTask = uploadBytesResumable(thumbStorageRef, thumb);

    thumbUploadTask.on(
        "state_changed",
        (snapshot) => {
            const percent = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );

            // update progress
            updateTheStore(s => {
                s.characterHandler.percent = percent
            })
        },
        (err) => console.log(err),
        () => {
            // download url
            getDownloadURL(thumbUploadTask.snapshot.ref).then((url) => {
                updateTheStore(s => {
                    s.characterHandler.uploadedThumbUrl = url
                })
                updateTheStore(s => {
                    s.characterHandler.percent = 0
                })
                updateTheStore(s => {
                    s.characterHandler.uploadedFile = null
                })
                console.log(url);
            });
        }
    );
}

}

function addCategory(category, character) {


    if(!character.categories.includes(category)) {
        character.categories.push(category)

        updateTheStore(s => {
            s.characterHandler.viewCharacter = character
        })

    }

}

function removeCategory(category, character) {

    let categories = character.categories || []


    var index = categories.indexOf(category);
    if (index !== -1) {
        categories.splice(index, 1);
    }

    character.categories = categories
    updateTheStore(s => {
            s.characterHandler.viewCharacter = character
        })
}


const CharacterEditor = ({character = null}) => {


    let testState = useStore.getState()


    let auth = testState.auth

    if (!auth) {
        return <>
            <div>WRONG</div>
        </>
    }

    if (!character) {
        character = {
            id: uuid(),
            name: "",
            birthYear: 400,
            origin: "",
            pronouns: "",
            owner: auth.currentUser.uid,
            blurb: "",
            description: "",
            npc: false,
            profileImage: "", 
            dead: false,
            isPublic: false,
            categories: []
        }
        updateTheStore(s => {
            s.characterHandler.viewCharacter = character
        })
    }




    return <>
        <div className="PLACEHOLDER">
            <table>
                <tr className="tr-nobg">
                    <td className="lefty-td"><h3>Profilbild</h3></td>
                    <td className="lefty-td"><h3>Information</h3></td>
                </tr>
                <tr className="tr-nobg">
                    <td>
                        <div>
                            <div className="lefty">
                                {character.profileImage &&
                                    <div><img className="profileImage" src={character.profileImage}></img>
                                    </div>}
                            </div>
                            <div className="lefty">
                                {character?.owner == auth.currentUser.uid &&
                                    <>
                                        <h4>Ladda upp ny bild:</h4>
                                        <input type="file" accept="image/*" onChange={uploadImage}/> <br />
                                        <button onClick={uploadImageToFirebase}>Upload Image</button>

                                        <div>{testState.characterHandler.percent != 0 && testState.characterHandler.percent} </div>
                                    </>
                                }

                            </div>
                        </div>
                    </td>
                    <td className="lefty-td">
                        <p>Namn: <input defaultValue={character.name} id="char_name"/></p>
                        <p>Födelseår: <input defaultValue={character.birthYear} id="char_birthyear"/> (Välj år 0 för okänt).
                        Är död? <Checkbox id="char_dead" defaultChecked={character.dead || false }/>   </p>
                        <p>Pronomen: <input defaultValue={character.pronouns} id="char_pronouns"/></p>
                        <p>Härkomst: <select defaultValue={character.origin} id="char_origin">
                            <option value="">-</option>
                            {constants.ALLCOUNTRIES.map(c => <option value={c}>{c}</option>)}
                        </select></p>
                        <p>Kort koncept: <input defaultValue={character.blurb} id="char_blurb" size={60}
                                                maxLength="60"/>
                            <span title="Kortfattad info om din karaktär. Max 60 tecken."><FontAwesomeIcon
                                icon="info-circle"/></span>
                        </p>
                        <p>Beskrivning: <textarea defaultValue={character.description} id="char_desc" cols="60"
                                                  rows="8"/></p>
                        <p>Kategorier: <select onChange={e => addCategory(e.currentTarget.value, character)}>
                            <option value={""}>-</option>
                            {constants.CHARCATEGORIES.map(c => <option value={c}>{c}</option>)}
                        </select></p>
                        {character.categories.map(cat => <>
                            <div className="test-left">{cat}<Button size="mini" onClick={()=>removeCategory(cat, character)} >X </Button>, </div>
                        </>)}

                    </td>
                </tr>
                
            </table>


            <p>Är NPC? <Checkbox id="char_npc" defaultChecked={character.npc}/></p>
            <p>
                <button onClick={() => saveCharacter(character)}>Spara karaktär</button>
            </p>
            <p>
                <button onClick={() => goBack()}>Avbryt</button>
            </p>
        </div>

    </>


};

export default CharacterEditor;