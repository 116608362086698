//LarpViewer.js

//OrganiserViewer.js

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {Dropdown, Button, Checkbox, Input, Table, Tab, List, Image} from "semantic-ui-react";
import './CharacterViewer.css'
import {useStore, updateTheStore} from './newState'
import {businessTemplates} from "./businesses";
import * as bh from './businessHandler'
import {getCopyOf, loadAllRelevantFromDB} from "./utilityFunctions";
import * as constants from './constants'
import * as ch from './currencyHandler'
import {v4 as uuid} from 'uuid';
import moment from 'moment'
import * as fb from './firebaseHandler'


async function chooseLarp(larpId) {


	if(larpId === "-") {
		return
	}

	updateTheStore(s => {
        s.larpViewerhandler.chosenLarp = larpId
    })


}

function Info() {
	let testState = useStore.getState()

	let chosenLarp = testState.larpViewerhandler.chosenLarp

	

	if(chosenLarp == "") {
		return <></>
	}

	let larp = testState.larps[chosenLarp]

	return <>

		<p>Namn: {larp.name}</p>
		<p>Plats: {larp.location}</p>
		<p>Start: {larp.startDate}</p>
		<p>Slut: {larp.endDate}</p>


	</>

}

async function submitParticipantForm(form) {

	let questions = Object.values(form.questions)

	let done = true

	let submission = {}

	for(const q of questions) {

		if(q.type === "Välj Flera") {

			let allChecked = []
			let atLeastOneChecked = false

			for(const opt of q.options) {
				let id = q.id+'-'+opt.split(" ").join("-")

				debugger


				let checked = document.getElementById(id).checked

				
				if(checked) {
					allChecked.push(opt)
					atLeastOneChecked = true
				}

			}

			if(!atLeastOneChecked) {
				done = false
				document.getElementById(q.id).style.backgroundColor = "red"
				//make question red somehow
			} else {
				document.getElementById(q.id).style.backgroundColor = ""
				submission[q.id] = allChecked.join(", ")
			}


		} else {

			let value = document.getElementById(q.id+'_val')?.value

			if(q.mandatory && (!value || value === "")) {
				document.getElementById(q.id+'_val').style.backgroundColor = "red"
				done = false

			} else {
				document.getElementById(q.id+'_val').style.backgroundColor = ""
				submission[q.id] = value
			}
		}

	}

	if(done) {
		let testState = useStore.getState()

		let chosenLarp = testState.larpViewerhandler.chosenLarp
		let larp = getCopyOf(testState.larps[chosenLarp])

		if(!larp.participantForm.submissions) {
			larp.participantForm.submissions = {}
		}

		larp.participantForm.submissions[testState.account.uid] = submission

		updateTheStore(s => {
        	s.larps[chosenLarp] = larp
    	})

		await fb.writeLarp(larp)

	}



}

function ChooseManyQuestion({question}) {

	return <>

	<strong>{question.text}</strong><br />
	<div id={question.id}>
	{question.options.map((opt) => <>
	<Checkbox id={question.id+'-'+opt.split(" ").join("-")} label={opt} /> <br />


	</>)}
	</div>
	</>

	
}

function ChooseOneQuestion({question}) {



	return <>
	<div className={ question.mandatory ? "bg_red" : "bg_green"  }>
	<strong>{question.text}</strong>

	<br />
	 <p> <select defaultValue="-" id={question.id+"_val"}>
                            <option value="">-</option>
                            {question.options.map(o => <option value={o}>{o}</option>)}
                        </select></p>
    </div>
	</>
}

function LongTextQuestion({question}) {


	return <>
	<strong>{question.text}</strong>
	<br />
	 <p><textarea defaultValue="" id={question.id+'_val'} cols="60"
                                                  rows="8"/></p>
	</>
}

function ShortTextQuestion({question}) {
return <>
	<strong>{question.text}</strong>
	<br />
	 <p><Input defaultValue="" id={question.id+'_val'} /></p>
	</>

}

function FormViewer({form}) {

	let testState = useStore.getState()

	let account = testState.account

	let name = form?.name || "Deltagarformulär"

	if(form.submissions && Object.keys(form.submissions).includes(account.uid)) {

		return <>
		<h1>{name}</h1>
		<div>Du har redan fyllt i deltagarformuläret. Tack för det. </div>
		</>
	}

	return <><div>{name}</div>

		{Object.values(form.questions).map((q) => <>
			{q.type === "Välj en" && <><ChooseOneQuestion question={q} /></>}
			{q.type === "Lång Text" && <><LongTextQuestion question={q} /></>}
			{q.type === "Välj Flera" && <><ChooseManyQuestion question={q} /></>}
			{q.type === "Kort Text" && <><ShortTextQuestion question={q} /></>}
			

		</>)}
		 <Button size="small" onClick={() => submitParticipantForm(form)}>Skicka in</Button> 
	</>


}

function Forms() {
	let testState = useStore.getState()

	let chosenLarp = testState.larpViewerhandler.chosenLarp

	

	if(chosenLarp == "") {
		return <></>
	}

	let larp = testState.larps[chosenLarp]

	let forms = []
	let participantForm = larp.participantForm

	return <>

	{participantForm !== null && <FormViewer form={participantForm} />}
		
	</>
	


}



const LarpViewer = () => { 

	let testState = useStore.getState()

	let account = testState.account
 

	let isAdmin = account.isAdmin

	let larps = testState.larps

	let publicLarps = Object.values(testState.larps).filter((l) => {

		return isAdmin || (moment().isBefore(moment(l.endDate)) && l.public)
	})


	return <>
		<select id="larps" onChange={e => chooseLarp(e.currentTarget.value)}>

                        <option value="">-</option>
                        {publicLarps.map((l) => (<option value={l.id}> {  larps[l.id].name  } </option>)  ) }  
              </select> 

        <Info />
        <Forms />

	</>
}



export default LarpViewer;