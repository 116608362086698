
import * as fb from './firebaseHandler'
import {useStore, updateTheStore} from './newState'


export function rollDice(amount, sides){
        let total = 0
        for(let i = 0; i < amount; i++) {
           total += Math.floor((Math.random() * sides) + 1);
        }
        return total
    }

export function getNumberBetween(low, high) {

    let val =  low + Math.floor(Math.random()*(high-low))
    
    return val
}

export function getDecimalBetween(low, high) {

  let upperRange = (high-low)*100
  let lowerRange = low*100

  let rand = Math.round(lowerRange+Math.random()*upperRange)/100



  let val = rand
    console.log(val)
    return val
}

export function getCopyOf(json) {

  return JSON.parse(JSON.stringify(json))
}

export async function logout() {

  let testState = useStore.getState()

  let auth = testState.auth



  if(!auth) {
    return
  }

  await auth.signOut()
  
  updateTheStore(s => {s.auth = null})
  updateTheStore(s => {s.account = null})

  updateTheStore(s => {s.characterHandler = { 
    characters: {},
        filteredCharacters: [],
        viewCharacter: null,
        editingACharacter: false,
        uploadedFile: null,
        percent: 0,
        uploadedImageUrl: ""
  }})

  
}

export function isModerator(account) {

  let testState = useStore.getState()

  return testState.moderators.includes(account.uid) || account.isAdmin
}

export function isCharacterPublic(character) {

  let testState = useStore.getState()

  let pubCharacters = Array.isArray(testState.characterHandler.publicCharacters) ? testState.characterHandler.publicCharacters : [] 
  
  return pubCharacters.includes(character) 
}

export async function loadAllRelevantFromDB() {

    let testState = useStore.getState()

    let characters = await fb.getCharacters()
    let profiles = await fb.getAccounts()
    let larps = await fb.getLarps()
    let moderators = await fb.getModerators()
    let publicCharacters = await fb.getUtil('publicCharacters')
    let events = await fb.getCharacterEvents()
    let shallowAccounts = await fb.getShallowAccounts()

 
    

    updateTheStore(s => {
        s.characterHandler.characters = characters
    })

    updateTheStore(s => {
        s.characterHandler.allEvents = events
    })

    updateTheStore(s => {
        s.characterHandler.publicCharacters = publicCharacters
    })

    updateTheStore(s => {
        s.moderators = moderators
    })

    updateTheStore(s => {
        s.larps = larps
    })

    updateTheStore(s => {
        s.profiles = profiles
    })
        updateTheStore(s => {
        s.shallowAccounts = shallowAccounts
    })


}