import logo from './logo.svg';
import './App.css';

import React, {useState, useEffect} from 'react';
import 'semantic-ui-css/semantic.min.css'
import {initializeApp} from "firebase/app"
import {getDatabase, ref, set, child, get} from "firebase/database"
import {getStorage} from "firebase/storage"
import {v4 as uuid} from 'uuid';
import {library} from '@fortawesome/fontawesome-svg-core'
import {fab} from '@fortawesome/free-brands-svg-icons'
import {faCheckSquare, faCoffee, faEnvelope, faUser, faInfoCircle} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {Dropdown, Button, Checkbox, Input, Table, Tab} from "semantic-ui-react";

import {SocialCard} from "./SocialCard";
import AccountViewer from "./AccountViewer";
import AccountEditor from "./AccountEditor";
import CharacterViewer from "./CharacterViewer";
import AdminViewer from "./AdminViewer";
import PokerViewer from "./PokerViewer";
import AboutViewer from './AboutViewer'
import {FiefViewer} from './FiefViewer'
import {FiefPrintViewer} from './FiefPrintView'
import OrganiserViewer from './OrganiserViewer'
import LarpViewer from './LarpViewer'
import TreeViewer from './TreeViewer'

import moment from 'moment'
import {useStore, updateTheStore} from './newState'
import {logout, loadAllRelevantFromDB} from './utilityFunctions'


import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut
} from "firebase/auth";
import {getFirestore, query, getDocs, collection, where, addDoc} from "firebase/firestore";
import {arableLand, foxCompany} from './businesses'

import {runBusiness} from './businessHandler'
import * as fb from './firebaseHandler'
import LoggedInHeader from "./LoggedInHeader";
import BusinessViewer from "./BusinessViewer";


const CURR_VERSION = "0.3.5"//"0.1.1.2"

const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({
    prompt: 'select_account'
});

library.add(fab, faCheckSquare, faCoffee, faEnvelope, faUser, faInfoCircle)

const firebaseConfig = {
    apiKey: "AIzaSyCl3_zVh1cZhJ5fyC7t-Oaqi9HOASHLC-8",
    authDomain: "gragripen-mit.firebaseapp.com",
    databaseURL: "https://gragripen-mit-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "gragripen-mit",
    storageBucket: "gragripen-mit.appspot.com",
    messagingSenderId: "582329223549",
    appId: "1:582329223549:web:58b5bb7ddd722283b72414"
}

function getCopyOf(json) {

    return JSON.parse(JSON.stringify(json))
}


function subtractCurrencies(lhf, rhf) {

    let base = lhf.base - rhf.base
    let lux = lhf.lux - rhf.lux
    let money = lhf.money - rhf.money
    let plot = lhf.plot - rhf.plot
    let influence = lhf.influence - rhf.influence
    let labours = lhf.labours - rhf.labours

    return {base, lux, money, plot, influence, labours}

}

function addCurrencies(lhf, rhf) {

    let base = lhf.base + rhf.base
    let lux = lhf.lux + rhf.lux
    let money = lhf.money + rhf.money
    let plot = lhf.plot + rhf.plot
    let influence = lhf.influence + rhf.influence
    let labours = lhf.labours + rhf.labours

    return {base, lux, money, plot, influence, labours}

}


const initialState = {
    auth: null,
    account: null,
    year: 400,
    month: 1,
    assets: {base: 0, lux: 0, money: 0, plot: 0, influence: 0, labours: 0},
    business: getCopyOf(arableLand),
    characters: [],
    profile: {editing: false},
    characterHandler: {
        characters: {},
        filteredCharacters: [],
        viewCharacter: null
    },
    characterViewer: {
        filters: {
            search: null
        },
        showGrid: true
    }
}


const app = initializeApp(firebaseConfig)

initialState.auth = getAuth(app);



async function signInWithGoogle() {
    let testState = useStore.getState()
    let auth = testState.auth

    let account = testState.account

    const db = getDatabase()
    try {
        const res = await signInWithPopup(auth, googleProvider);
        const user = res.user;
        account = await fb.getAccount(user.uid)
        if (!account) {
            account = {
                uid: auth.currentUser.uid,
                email: {value: auth.currentUser.email, public: false},
                name: {value: auth.currentUser.displayName, public: false},
                nickname: {value: "", public: true},
                pronouns: {value: "", public: false},
                discord: {value: "", public: false},
                personal: {}
            }


            await fb.writeAccount(account)

        }

        if(!account.personal) {
            account.personal = {}
        }
        updateTheStore(s => {
            s.account = account
        })
        updateTheStore(s => {
            s.auth = auth
        })

        updateTheStore(s => {
            s.storage =  getStorage(app);
        })

        await loadAllRelevantFromDB()
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};


function ProfileViewer() {

    let testState = useStore.getState()

    let auth = testState.auth

    let test = Math.random()

    let account = testState.account

    if (!account) {
        return <></>
    }


    let isOwner = account.uid === auth.currentUser.uid || account.isAdmin === true
    return <>
        {testState.profile.editing && <AccountEditor account={account} isOwner={isOwner}/>}
        {!testState.profile.editing && <AccountViewer isOwner={isOwner}/>}
    </>


}

function LoginScreen() {
    let testState = useStore.getState()

    return <>
        <div className="account-container">
            <div className="first">
                <p><img src="/gragripen.png" alt="image" height="350"/></p>
                {!testState.auth?.currentUser && <div>
                    <Button onClick={() => signInWithGoogle()}>Login with Google</Button></div>}

            </div>
            <div className="last">
                <div className="endnote"> Version: {CURR_VERSION} </div>
            </div>
        </div>
    </>
}


function App() {

    async function saveCharacter() {

        let testState = useStore.getState()

        let characters = testState.characters
        let auth = testState.auth

        let id = uuid()
        let name = document.getElementById("char_name").value
        let birthYear = document.getElementById("char_birthyear").value
        let origin = document.getElementById("char_origin").value
        let description = document.getElementById("char_desc").value
        let creator = auth?.currentUser?.uid


        if (!creator) {
            return
        }

        let character = {id, name, origin, birthYear, description, creator}

        characters.push(character)


        await fb.writeCharacter(character)

        updateTheStore(s => {
            s.characters = characters
        })

    }


    updateTheStore(s => {
        s.business = initialState.business
    })
    updateTheStore(s => {
        s.auth = initialState.auth
    })

    let testState = useStore(s => s)



        const mainPanes = [
                {
                    menuItem: 'Din Profil', render: () => <Tab.Pane>
                        <div>
                            <ProfileViewer/>
                        </div>
                    </Tab.Pane>
                },
                {
                    menuItem: 'Karaktärer', render: () => <Tab.Pane>
                        <div>
                            <CharacterViewer/>
                        </div>
                    </Tab.Pane>
                }, {
                  menuItem: 'Om', render: () => <Tab.Pane>
                        
                        <AboutViewer />  
                        
                    </Tab.Pane>
                },{
                  menuItem: 'Lajv', render: () => <Tab.Pane>
                        
                        <LarpViewer />  
                        
                    </Tab.Pane>
                },{
                menuItem: 'Fiefs', render: () => <Tab.Pane>
                    <div>
                        Kommer tillbaka senare. 
                    </div>
                </Tab.Pane>
                }
            ]

        let currentOrganisedByAccount = Object.values(testState.larps).filter((l) => {
            return l.organisers.includes(testState.account?.uid) && moment(l.endDate) > moment() 
        })

        if(currentOrganisedByAccount.length > 0) {
            let pane = {
                    menuItem: 'Arrangör', render: () => <Tab.Pane>
                        <div>
                            <OrganiserViewer/>
                        </div>
                    </Tab.Pane>
                }
          mainPanes.push(pane)

        }

        if(testState?.account?.isAdmin) {
          let pane = {
                    menuItem: 'Admin', render: () => <Tab.Pane>
                        <div>
                            <AdminViewer/>
                        </div>
                    </Tab.Pane>
                }
          mainPanes.push(pane)
          let pokerPane = {
                    menuItem: 'Poker', render: () => <Tab.Pane>
                        <div>
                            <PokerViewer/>
                        </div>
                    </Tab.Pane>
                }
          mainPanes.push(pokerPane)
          let treePane = {
                    menuItem: 'Tree', render: () => <Tab.Pane>
                        <div>
                            <TreeViewer/>
                        </div>
                    </Tab.Pane>
                }
          mainPanes.push(treePane)
        }

        return (<>
                <head>
                    <script src="https://kit.fontawesome.com/c846419c2e.js" crossorigin="anonymous"></script>
                </head>
                <div className="App">


                    {!testState.auth?.currentUser && <LoginScreen/>}
                    {testState.auth?.currentUser &&
                        <div className="main-container">
                            <div className="grid-header"><LoggedInHeader/></div>
                            <div className="grid-panes"><Tab panes = {mainPanes} /></div>
                            <div className="grid-footer">La di da!</div>

                        </div>


                        }
                </div>
            </>
        );


}

export default App;
