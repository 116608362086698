//PokerViewer.js


import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {Dropdown, Button, Checkbox, Input, Table, Tab, List, Image} from "semantic-ui-react";
import './CharacterViewer.css'
import {useStore, updateTheStore} from './newState'
import {businessTemplates} from "./businesses";
import * as bh from './businessHandler'
import {getCopyOf, loadAllRelevantFromDB} from "./utilityFunctions";
import * as constants from './constants'
import {get} from "firebase/database";
import * as ch from './currencyHandler'
import {v4 as uuid} from 'uuid';
import * as fb from './firebaseHandler'
import * as poker from './poker'



function setPokerHand(card, place) {

	updateTheStore(s => {
        s.poker[place] = card
    })

}

async function updateHand() {
	let testState = useStore.getState()

	let result

	let deal = testState.poker

	let path = ""

	if(deal.hand1 =="" || deal.hand2 == "") {
		return 
	}

	path = deal.hand1+deal.hand2

	if(deal.flop1 == "" || deal.flop2 =="" || deal.flop3 == "") {
		result = await fb.getPokerResults(path)
	} else {
		path = path + "/flops/" + deal.flop1+deal.flop2+deal.flop3
	

		if(deal.turn == "") {
			result = await fb.getPokerResults(path)
		} else {
			path = path + "/turns/" + deal.turn
		

			if(deal.river == "") {
				result = await fb.getPokerResults(path)
			} else {
				path = path + "/rivers/" + deal.river
			}

			result = await fb.getPokerResults(path)
		}
	}

	if(result && result.wins != null) {

		let total = result.wins+result.losses+result.draws

		result.total = total

		updateTheStore(s => {
        	s.poker.results = result
    	})


	} else {
		updateTheStore(s => {
        	s.poker.results = {}
    	})

	}



}


const PokerViewer = () => {

    let testState = useStore.getState()
    
    let deal = testState.poker

    let freshDeck = getCopyOf(poker.alternateDeck).sort()

    let percentages = {wins: (deal.results.wins/deal.results.total), 
					   losses: (deal.results.losses/deal.results.total),
					   draws: (deal.results.draws/deal.results.total)}

	let percentagesPlayers = {
		two: {
				wins: Math.round((percentages.wins)*1000)/1000,
				losses: Math.round((percentages.losses)*1000)/1000,
				draws: Math.round((percentages.draws)*1000)/1000
			},
		three: {
				wins: Math.round((percentages.wins*percentages.wins)*1000)/1000, 
				losses: Math.round((percentages.losses*percentages.losses)*1000)/1000, 
				draws: Math.round((percentages.draws*percentages.draws)*1000)/1000
			},
		four: {
				wins: Math.round((percentages.wins*percentages.wins*percentages.wins)*1000)/1000,
				losses: Math.round((percentages.losses*percentages.losses*percentages.losses)*1000)/1000, 
				draws: Math.round((percentages.draws*percentages.draws*percentages.draws)*1000)/1000
			},
		five: {
			wins: Math.round((percentages.wins*percentages.wins*percentages.wins*percentages.wins)*1000)/1000, 
			losses: Math.round((percentages.losses*percentages.losses*percentages.losses*percentages.losses)*1000)/1000, 
			draws: Math.round((percentages.draws*percentages.draws*percentages.draws*percentages.draws)*1000)/1000
		},
		six: {
			wins: Math.round((percentages.wins*percentages.wins*percentages.wins*percentages.wins*percentages.wins)*1000)/1000, 
			losses: Math.round((percentages.losses*percentages.losses*percentages.losses*percentages.losses*percentages.losses)*1000)/1000, 
			draws: Math.round((percentages.draws*percentages.draws*percentages.draws*percentages.draws*percentages.draws)*1000)/1000
		},
	}


    return <><div>
        <br />
        <br />

        <Button onClick={() => poker.runExternal(2)} >Spela Poker</Button>

        <div>Hand: <select onChange={e => setPokerHand(e.currentTarget.value, "hand1")}>

                        <option value="">-</option>
                        {freshDeck.map((card) => (<option value={card}> {card} </option>)  ) }  
              		</select>
              		- 
              		<select onChange={e => setPokerHand(e.currentTarget.value, "hand2")}>

                        <option value="-">-</option>
                        {freshDeck.map((card) => (<option value={card}> {card} </option>)  ) }  
              		</select>
        </div>
        <div>Flop: <select onChange={e => setPokerHand(e.currentTarget.value, "flop1")}>

                        <option value="">-</option>
                        {freshDeck.map((card) => (<option value={card}> {card} </option>)  ) }  
              		</select>
              		- 
              		<select onChange={e => setPokerHand(e.currentTarget.value, "flop2")}>

                        <option value="">-</option>
                        {freshDeck.map((card) => (<option value={card}> {card} </option>)  ) }  
              		</select>
              		-
              		<select onChange={e => setPokerHand(e.currentTarget.value, "flop3")}>

                        <option value="">-</option>
                        {freshDeck.map((card) => (<option value={card}> {card} </option>)  ) }  
              		</select>
        </div>
        <div>Turn: <select onChange={e => setPokerHand(e.currentTarget.value, "turn")}>

                        <option value="">-</option>
                        {freshDeck.map((card) => (<option value={card}> {card} </option>)  ) }  
              		</select>
              		- 
        </div>
        <div>River: <select onChange={e => setPokerHand(e.currentTarget.value, "river")}>

                        <option value="">-</option>
                        {freshDeck.map((card) => (<option value={card}> {card} </option>)  ) }  
              		</select>
              		- 
        </div>

           


        <Button onClick={() => updateHand()} >Uppdatera hand</Button>


        <p>{JSON.stringify(deal.results)}</p>
        <p>Percentages: {JSON.stringify(percentages)}</p>
        <p>Two Players: {JSON.stringify(percentagesPlayers.two)}</p>
        <p>Three Players: {JSON.stringify(percentagesPlayers.three)}</p>
        <p>Four Players: {JSON.stringify(percentagesPlayers.four)}</p>
        <p>Five Players: {JSON.stringify(percentagesPlayers.five)}</p>
        <p>Six Players: {JSON.stringify(percentagesPlayers.six)}</p>

        

    </div>

    <div>
    </div>
    </>
};


export default PokerViewer;